import React, { Component } from 'react';
import ImageGallery from '../Imgpop';
import "./style.css"

class Video extends Component {
    render() {
        const imageUrls = [
            require('./img/video/transcript.png'),
            require('./img/video/upload.png'),
            require('./img/video/upload-confirmation.png'),
        ];
        return (
            <div className='collapsible'>
                <h1>Video Player & Upload Tool</h1>
                <div className="gradient"></div>
                <p className='eyebrow mt-4'>Accessible video player and vimeo upload form</p>

                <div className="details">
                    <p>
                        The video player and upload tool were developed with a student-centric approach, offering accessible videos with search and transcript printing capabilities. Faculty can utilize the tool to upload welcome videos and other resources for students, enhancing the learning experience. Additionally, staff can efficiently upload in-house created videos by UOPX. The tool supports video embedding in various platforms, including the Blackboard classroom, through its integration with the Vimeo API.
                    </p>

                    <p className='eyebrow2 mt-5'>Laptop & mobile views</p>
                    <div class="row mt-3">
                        <div className='col-sm-8'>
                            <img className="laptop-view" src={require('./img/video/laptop.png')} alt="laptop" />
                        </div>
                        <div className='col-sm-4'>
                            <img className="mobile-view" src={require('./img/video/mobile.png')} alt="mobile" />
                        </div>
                    </div>
                    <p className='mt-3'>This tool was crafted using HTML, PHP, jQuery, PHPMailer, Autoload, and integrated with Vimeo API and Asana API for efficient tracking of uploaded videos.</p>
                    <p className='eyebrow2 mt-5'>Additonal views</p>
                    <div className="thumb-imgs mt-3">
                        <ImageGallery imageUrls={imageUrls} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Video;