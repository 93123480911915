import React, { Component } from 'react';
import "./style.css"

class Intro extends Component {
    render() {
        return (
            <div>
                <div className='d-flex'>
                    <h1>Welcome.</h1>
                    <img className="intro-img" src={require('./img/me-taj.png')} alt="Sarah in india" />
                </div>
                <div className="gradient"></div>
                <div className="details">
                    <p>Hello there! I'm Sarah Clark, a creative person who shifted gears from the world of interior design to the dynamic realm of web development. My journey is fueled by my passion for learning and growth. I am always eager to embrace new challenges in the ever-evolving tech world.</p>
                    <p>Merging 14+ years of diverse experiences, I've honed my skills in creating engaging digital experiences. My entrepreneurial journey, managing teams, and building businesses have deepened my business acumen, driving my passion for web development further.</p>
                    <p>My inspiration comes from continual learning, traveling and experiencing diverse cultures and landscapes, and spending time with my family, friends, and of course my pups Max and Mia.</p>
                    <p>I’m excited to bring my unique blend of technical expertise, creativity, and leadership to exciting new projects.</p>
                </div>
            </div>
        )
    }
}

export default Intro;