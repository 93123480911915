// ImageGallery.js
import React, { useState } from 'react';
import './style.css';

const ImageGallery = ({ imageUrls }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openPopup = (index) => {
    setSelectedImage(index);
  };

  const closePopup = () => {
    setSelectedImage(null);
  };

  return (
    <div className="image-gallery row">
      {imageUrls.map((imageUrl, index) => (
        <div className='col-sm-4 mb-3'>
          <img
            key={index}
            src={imageUrl}
            alt={`${index + 1}`}
            className="gallery-image"
            onClick={() => openPopup(index)}
          />
          </div>
      ))}

      {selectedImage !== null && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content">
            <img
              src={imageUrls[selectedImage]}
              alt={`${selectedImage + 1}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;