import React, { Component } from 'react';
import MainNav from "../components/Nav";
// import SideNav from "../components/SideNav";
import { Nav } from 'react-bootstrap';
import Footer from "../components/Footer";
import Intro from "../components/Intro";
import Cwe from "../components/Projects/cwe";
import Mcpbuilder from "../components/Projects/mcpbuilder";
import Curriculum from "../components/Projects/curriculum";
import Video from "../components/Projects/vimeo_video";
import "./style.css"

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleElement: 'intro',
        };
    }

    handleToggle = (targetId) => {
        this.setState({ visibleElement: targetId });
    };

    render() {
        // const { focusedprojectAnchor } = this.state;

        return (
            <div>
                <div className="vertical-center">
                    <div className='container'>
                        <div>
                            <MainNav />
                            <div className="inner-container">
                                <div className='row'>
                                    <div className='col-sm-8'>
                                        <div id='intro' style={{ display: this.state.visibleElement === 'intro' ? 'block' : 'none' }}><Intro /></div>
                                        <div id='cwe' style={{ display: this.state.visibleElement === 'cwe' ? 'block' : 'none' }}><Cwe /></div>
                                        <div id='curriculum' style={{ display: this.state.visibleElement === 'curriculum' ? 'block' : 'none' }}><Curriculum /></div>
                                        <div id='mcp' style={{ display: this.state.visibleElement === 'mcp' ? 'block' : 'none' }}><Mcpbuilder /></div>
                                        <div id='video' style={{ display: this.state.visibleElement === 'video' ? 'block' : 'none' }}><Video /></div>
                                    </div>
                                    <div className='col mt-4'>
                                        <h2>Projects</h2>
                                        <div className="project-wrapper mt-4">
                                            <Nav className="" id="navbar-link">
                                                <ul>
                                                    <Nav.Link className="main-link" href="#" onClick={() => this.handleToggle('cwe')}>Center for Writing Excellence</Nav.Link>
                                                    <Nav.Link className="main-link" href="#" onClick={() => this.handleToggle('curriculum')}>Curriculum Hub</Nav.Link>
                                                    <Nav.Link className="main-link" href="#" onClick={() => this.handleToggle('mcp')}>MCP Builder</Nav.Link>
                                                    <Nav.Link className="main-link" href="#" onClick={() => this.handleToggle('video')}>Video Player & Upload Tool</Nav.Link>
                                                </ul>
                                            </Nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Main;