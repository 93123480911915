import React, { Component } from 'react';
import "./style.css"

class Footer extends Component {
    render() {
        return (
            <footer className="footer justify-content-center d-flex">
                <p className="footer-site">&copy;{new Date().getFullYear()} sarahcclark.com</p>
            </footer>
        )
    }
}

export default Footer;