import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import "./nav.css"

class MainNav extends Component {
    handleRefresh = () => {
        window.location.reload();
      };
    render() {
        return (
            <Navbar id="main-nav" collapseOnSelect expand="lg">
                <Navbar.Brand className="navBrand" href="#" onClick={this.handleRefresh}>
                    <span className="icon">SC</span>
                    {/* <img className="icon" src="/images/icon.jpeg" alt="img-1" /> */}
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className="navbar-light justify-content-end" id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav className="" id="navbar-link">
                        <Nav.Link className="main-link" href="https://github.com/scclark85" target="_blank"><i class="fa-brands fa-github fa-lg"></i></Nav.Link>
                        <Nav.Link className="main-link" href="https://www.linkedin.com/in/sarah-c-clark/" target="_blank"><i class="fa-brands fa-linkedin-in"></i></Nav.Link>
                        <Nav.Link className="main-link" href="mailto: sarahchristinec85@gmail.com"><i className="fa-regular fa-envelope fa-lg"></i></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default MainNav;