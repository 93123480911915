import React, { Component } from 'react';
import ImageGallery from '../Imgpop';
import "./style.css"

class CWE extends Component {
    render() {
        const imageUrls = [
            require('./img/cwe/hm.png'),
            require('./img/cwe/citation-gen.png'),
            require('./img/cwe/citation-result.png'),
        ];
        return (
            <div className='collapsible'>
                <h1>Center for Writing Excellence</h1>
                <div className="gradient"></div>
                <p className='eyebrow mt-4'>Tools and resources for student success</p>
                <div className="details">
                    <p>
                        The reconstructed CWE (Center for Writing Excellence) website has been transformed to offer students a more personalized and organized writing experience. The revamped site provides an array of tools and resources tailored to enhance the writing process. Notably, a built-in citation generator simplifies the intricate task of citing sources, contributing to the site's commitment to student success. The platform accommodates over 85,000 visits monthly, highlighting its significance as a valuable resource for students seeking support in their writing endeavors.
                    </p>

                    <p className='eyebrow2 mt-5'>Laptop & mobile views</p>
                    <div class="row mt-3">
                        <div className='col-sm-8'>
                            <img className="laptop-view" src={require('./img/cwe/laptop.png')} alt="laptop" />
                        </div>
                        <div className='col-sm-4'>
                            <img className="mobile-view" src={require('./img/cwe/mobile.png')} alt="mobile" />
                        </div>
                    </div>
                    <p class="mt-3">This custom WordPress plugin, crafted with PHP, jQuery, HTML, CSS, Bootstrap 5, and API integration, adheres to WCAG guidelines and ensures mobile-friendly functionality.</p>
                    <p className='eyebrow2 mt-5'>Additional views</p>
                    <div className="thumb-imgs mt-3">
                        <ImageGallery imageUrls={imageUrls} />
                    </div>
                </div>
            </div>
        )
    }
}

export default CWE;


