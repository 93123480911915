import React, { Component } from 'react';
import ImageGallery from '../Imgpop';
import "./style.css"

class McpBuilder extends Component {
    render() {
        const imageUrls = [
            require('./img/mcp/form1.png'),
            require('./img/mcp/form2.png'),
            require('./img/mcp/form3.png'),
            require('./img/mcp/preview.png'),
        ];

        return (
            <div className='collapsible'>
                <h1>MCP Builder</h1>
                <div className="gradient"></div>
                <p className='eyebrow mt-4'>HTML code generator for MCP message system</p>

                <div className="details">
                    <p>
                        The MCP (Message Communication Platform) builder was developed to empower employees with a streamlined process for crafting messages within the company's standardized design framework. This innovative tool allows users to select specific design elements, ensuring consistency while promoting individual customization. The builder generates simple HTML code, enabling employees to download their customized messages locally. The ease of copy-and-paste functionality facilitates a seamless integration into the MCP message system. Notably, the platform's swift notification feature promptly alerts users to any system outages, enhancing communication efficiency and ensuring timely responses within the organization.
                    </p>
                    <p className='eyebrow2 mt-5'>Laptop & mobile views</p>
                    <div class="row mt-3">
                        <div className='col-sm-8'>
                            <img className="laptop-view" src={require('./img/mcp/laptop.png')} alt="laptop" />
                        </div>
                        <div className='col-sm-4'>
                            <img className="mobile-view" src={require('./img/mcp/mobile.png')} alt="mobile" />
                        </div>
                    </div>
                    <p className='eyebrow2 mt-5'>Additonal views</p>
                    <div className="thumb-imgs mt-3">
                        <ImageGallery imageUrls={imageUrls} />
                    </div>
                </div>
            </div>
        )
    }
}

export default McpBuilder;