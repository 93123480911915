import React, { Component } from 'react';
import ImageGallery from '../Imgpop';
import "./style.css"

class Toolmart extends Component {
    render() {
        const imageUrls = [
            require('./img/curriculum-hub/toolmart-vendor.png'),
            require('./img/curriculum-hub/toolmart-backend_vendor.png'),
            require('./img/curriculum-hub/toolmart-backend_review.png'),
            require('./img/curriculum-hub/course-model.png'),
            require('./img/curriculum-hub/course-guide.png')
        ];
        return (
            <div className='collapsible'>
                <h1>Curriculum Hub</h1>
                <div className="gradient"></div>
                <p className='eyebrow mt-4'>A hub for guides and vendor tools</p>
                <div className="details">
                <p>The Curriculum Hub serves as a centralized content managment system for curriculum-related resources and guides, utilizing custom post types for seamless organization. Its structured menu system automatically populates, simplifying access to newly added posts. This hub empowers staff to efficiently update and edit multiple guides accessible to faculty and students alike. Additionally, it plays a pivotal role in tracking vendor tool performances, contributing to an annual cost savings of approximately $75,000 in 2023. Through Single Sign-On (SSO), selective post visibility ensures that certain content is restricted to students and/or staff, enabling users to determine what is available with ease.</p>
                <p className='eyebrow2 mt-5'>Laptop & mobile views</p>
                <div class="row mt-3">
                    <div className='col-sm-8'>
                        <img className="laptop-view" src={require('./img/curriculum-hub/laptop.png')} alt="laptop" />
                    </div>
                    <div className='col-sm-4'>
                        <img className="mobile-view" src={require('./img/curriculum-hub/mobile.png')} alt="mobile" />
                    </div>
                </div>
                <p className='mt-3'>This custom WordPress plugin, developed with PHP, jQuery, CSS, Bootstrap 5, and the WordPress REST API, leverages custom post types for enhanced functionality.</p>
                <p className='eyebrow2 mt-5'>Additonal views</p>
                <div className="thumb-imgs mt-3">
                    <ImageGallery imageUrls={imageUrls} />
                </div>
                </div>
            </div>
        )
    }
}

export default Toolmart;