import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/main.js";
// import About from "./pages/about";
// import Resume from "./pages/resume";
// import Uopx from "./pages/university-of-phoenix";
import "bootstrap/dist/css/bootstrap.min.css";

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Main />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
